import { navigate } from 'gatsby'
import queryString from 'query-string'
import React, { useState, useEffect } from 'react'

import { PostContent } from '../../components/contents/PostContent'
import SinglePost from '../../components/contents/SinglePost'
import { microcms } from '../../utils/microcms'

type Props = {
  location: Window['location'] & {
    pathname: string
    search: string
    state: string
    hash: string
    key?: string | undefined
  }
}

/**
 * Interviews Draft
 *
 * @param location location information.
 * @returns the Interviews draft content.
 */
const InterviewsDraftPage = ({ location }: Props) => {
  const { contentId, draftKey } = queryString.parse(location.search)

  // 配列で入ってくるパターンはプレビューページに存在しないため404へリダイレクトしています。
  if (typeof contentId === 'object' || typeof draftKey === 'object') {
    navigate('../../404')
    return
  }

  const [data, setData] = useState<GatsbyTypes.MicrocmsInterviews | null>(null)

  useEffect(() => {
    microcms
      .get({
        endpoint: 'interviews',
        contentId: contentId ?? '',
        queries: { draftKey: draftKey ?? '' }
      })
      .then(async (res) => {
        await setData({
          ...res,
          location
        })
      })
      .catch(() => {
        navigate('../../404')
      })
  }, [contentId, draftKey])

  return (
    <SinglePost
      slug="interviews"
      title={data?.name!}
      pageId={data?.id!}
      image={data?.coverImage?.url ?? ''}
      pathname={location.pathname}
      noindex={true}
    >
      {data && <PostContent postData={data} linkTitle="事例一覧へ" slug="/interviews" />}
    </SinglePost>
  )
}

export default InterviewsDraftPage
